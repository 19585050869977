import { IssueBase } from '.'
import { getLinkedIssueData, getMr } from '~/api/mrs'
import { getIssue, updateWeight } from '..'

/**
 * this factor is used to multiply the weight of tech debts and make it possible to use
 * a bigger granularity for them (from 0.25SP on).
 * Reason: Gitlab does not allow to set a weight lower than 1.
 */
const TD_WEIGHT_MULTIPLIER = 4

/**
 * merge requests generated by "resolve threads" feature in gitlab
 * can be recgnized by the following pattern in the description:
 * @example "The following discussion from !2243 should be addressed:"
 * where 2243 is the id of the MR that generated the tech debt
 */
const TECH_DEBT_MR_REGEX =
  /^The following discussions? from !([0-9]+)(\ \(merged\))? should be addressed:/

const loadRelatedIssue = async (
  projectId: number,
  mrIid: number,
  tdIid: number,
) => {
  const mr = await getMr(projectId, mrIid)
  const { issueIid, projectId: mrProjectId } = getLinkedIssueData(mr)
  if (issueIid === undefined) return undefined
  if (mrProjectId !== projectId) {
    console.warn(
      `tech debt ${tdIid} is linked to issue ${issueIid} in project ${projectId} but the tech debt is in project ${projectId}`,
    )
    return undefined
  }

  try {
    const relatedTechIssue = await getIssue(projectId, issueIid)
    return new IssueBase(relatedTechIssue)
  } catch (error) {
    console.warn(
      `could not load tech debt #${issueIid} of project ${projectId} related to issue #${tdIid}`,
    )
    return undefined
  }
}

@store()
export class TechDebt extends IssueBase {
  @lazy(async (td) => {
    if (td.relatedMrIid === undefined) return undefined
    return loadRelatedIssue(td.projectId, td.relatedMrIid, td.iid)
  })
  private relatedIssue?: IssueBase | undefined

  get estimate() {
    return this._fromApi.weight ?? undefined
  }

  get tShirt() {
    return this.customData.tShirt
  }

  get weight() {
    const bestWeight = this.estimate ?? this.tShirt
    return bestWeight ? bestWeight / TD_WEIGHT_MULTIPLIER : bestWeight
  }

  get relatedTechIssue() {
    return this.relatedIssue
  }

  get relatedMrIid() {
    const mrIid = this.description?.match(TECH_DEBT_MR_REGEX)?.[1]
    if (mrIid !== undefined) return Number(mrIid)
  }

  async setEstimate(weight?: number, debounced = false) {
    const updated = await this.queue(
      'setEstimate',
      async () => {
        const w = weight === undefined ? null : weight * TD_WEIGHT_MULTIPLIER
        return updateWeight(this.projectId, this.iid, w)
      },
      debounced,
    )
    if (updated) this._fromApi.weight = updated.weight
  }
}
