import { CFG } from '~/services/cfg'
import { getIssue, updateDescription } from '~/api/issues'

// https://gitlab.com/kampaay/general/-/issues/3075
export const SPRINTS_CFG_ISSUE_ID = 3075

// https://gitlab.com/kampaay/general/-/issues/2734
export const SPRINTS_RESULTS_ISSUE_ID = 2734

// https://gitlab.com/kampaay/general/-/issues/3054
export const PLANNING_ISSUE_ORDER = 3054

// https://gitlab.com/kampaay/general/-/issues/3095
export const SEEDS_ISSUE_ORDER = 3095

// https://gitlab.com/kampaay/general/-/issues/3234
export const CANDIDATE_SPRINT_ISSUES = 3234

const generalProjectId = CFG.projects.general.id

const getIssueDesc = async (issueId: number) =>
  (await getIssue(generalProjectId, issueId)).description!

export class SpecialIssue<TEntity extends object> {
  @lazy((si) => getIssueDesc(si.issueId))
  protected _data?: string
  constructor(private issueId: number) {}
  protected _parse(serialized: string) {
    return JSON.parse(serialized) as TEntity
  }
  protected _write(data: TEntity) {
    return JSON.stringify(data)
  }
  protected get data() {
    // side effect implicit load
    if (this._data !== undefined) {
      return this._parse(this._data)
    }
  }
  /**
   * returns updated data after applying the updaterOrValue (if function), otherwise the value itself
   * in case of function, the current data could be passed as an argument
   * in that case it will not force the refetch of the data
   * @param updaterOrValue
   * @param current
   * @returns
   */
  private async getDataToBeSaved(
    updaterOrValue: TEntity | ((data: TEntity) => TEntity),
    current?: TEntity,
  ) {
    if (typeof updaterOrValue === 'function') {
      return updaterOrValue(
        (current ??= this._parse(await getIssueDesc(this.issueId))),
      )
    }
    return updaterOrValue
  }
  protected async _save(
    updaterOrValue: TEntity | ((data: TEntity) => TEntity),
  ) {
    // reducing concurrency problems: we avoid overriding the whole payload with data we fetched time ago.
    // We fetch the most recent version and update only the specific part

    // optimistic update (no force fetch)
    this._data = this._write(
      await this.getDataToBeSaved(updaterOrValue, this.data),
    )

    // real update: forces re-fetch
    const data = await this.getDataToBeSaved(updaterOrValue)

    this._data = (
      await updateDescription(generalProjectId, this.issueId, this._write(data))
    ).description!
    return this.data!
  }
}
