/**
 * creates a writable computed that tracks a reactive getter.
 * When the getter's reactive dependencies change,
 * the computed is updated overriding any direct update.
 * See tests for usage examples and use cases.
 *
 * @param getter
 * @param watchOptions
 * @returns
 */
export const useTrack = <T>(getter: (oldValue?: T) => T) => {
  let toBeInitialized = true
  const val = ref<T>()

  // oldVal should not be reactive so that getter access
  // does not trigger a new reactivity cycle generating the error:
  // "Maximum recursive updates exceeded. This means you have a reactive effect that is mutating its own dependencies and thus recursively triggering itself."
  let oldVal: T | undefined

  return computed({
    get() {
      if (toBeInitialized) {
        watch(
          () => getter(oldVal),
          (item) => {
            val.value = oldVal = item
          },
          { immediate: true },
          // https://vuejs.org/guide/essentials/watchers#sync-watchers
          // { immediate: true, flush: 'sync' },
        )
        toBeInitialized = false
      }
      return val.value as T
    },
    set(v) {
      val.value = v
      // fromGetter.value = false
    },
  })

  // const cloned = ref(getter(true))
  // watch(
  //   () => getter(false),
  //   (item) => {
  //     cloned.value = item
  //   },
  //   watchOptions,
  // )
  // return cloned as Ref<T>
}

/**
 * @file utility functions as syntactic sugar or to empower refs addressing common use cases
 */

export type RefPlugin<T> = {
  /**
   * the hook is triggered when the value changes (even for the initialization)
   * @param value
   * @param isInit true if the change is due to initialization
   * @returns
   */
  onChange: (value: T, isInit: boolean) => void
}

/**
 * creates a ref and whatches for any change (deep!) that is transmitted to the plugins
 * @param getInitialValue returns the initial value the ref should have
 * @param plugins
 * @returns
 */
export const withPlugins = <T>(
  getInitialValue: () => T,
  ...plugins: RefPlugin<T>[]
) => {
  const initialValue = getInitialValue()
  const r = ref<T>(initialValue)
  watch(
    r,
    (value) => {
      plugins.forEach((plugin) => plugin.onChange(value as T, false))
    },
    { deep: true },
  )
  plugins.forEach((plugin) => plugin.onChange(initialValue, true))
  return r as Ref<T>
}
