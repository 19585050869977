import { SpecialIssue } from './SpecialIssue'

/**
 * A simple data container that exposes save, load and data properties
 */
@store()
export class SimpleContainer<T extends object> extends SpecialIssue<T> {
  save(data: T) {
    return super._save(data)
  }
  override get data() {
    return super.data
  }
}
