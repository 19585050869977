import { CFG, LABELS } from '~/services/cfg'
import { getIssueLinks } from '..'

// API level caching
export const loadIssueLinks = singletonFactory(
  async (projectId: number, iid: number) => getIssueLinks(projectId, iid),
  (projectId: number, iid: number) => `${projectId}--${iid}`,
)

// model level caching
export const loadBlockedIssues = async (
  projectId: number,
  iid: number,
  force = false,
): Promise<GLIssueLink[]> => {
  const res = await (force ? loadIssueLinks.force : loadIssueLinks)(
    projectId,
    iid,
  )
  // const res = (await getIssueLinks(projectId, iid))
  return res.filter((i) => i.link_type === 'is_blocked_by')
}

export const loadTechIssues = (iid: number) =>
  loadIssueLinks(CFG.projects.general.id, iid).then((tis) =>
    tis.filter(
      (link) =>
        link.link_type === 'relates_to' &&
        link.project_id !== CFG.projects.general.id,
    ),
  )

export const loadSeeds = (iid: number, force = false) =>
  (force ? loadIssueLinks.force : loadIssueLinks)(
    CFG.projects.general.id,
    iid,
  ).then((tis) =>
    tis.filter(
      (link) =>
        link.state !== 'closed' &&
        link.link_type === 'relates_to' &&
        link.project_id === CFG.projects.general.id &&
        link.labels.includes(LABELS.seeds),
    ),
  )
