import type { UnwrapRef } from 'vue'

/**
 * forces the load of lazy branches and returns the loaded instances.
 * @param sources
 * @returns
 */
export const lload = <T extends any[]>(
  ...sources: { [K in keyof T]: () => T[K] | undefined }
) => {
  const values = sources.map((source) => source())
  if (values.some((v) => v === undefined)) {
    return new Promise<T>((resolve) => {
      const stop = watch(
        sources,
        (vals) => {
          if (!vals.some((v) => v === undefined)) {
            stop()
            resolve(vals as T)
          }
        },
        { immediate: true, deep: false },
      )
    })
  }
  return Promise.resolve(values as T)
}

/**
 * Returns a new object augmented with the computed properties.
 * - properties are individually computed only when accessed
 * - no `.value` access needed
 * - it's possible to merge them in another object
 * these are the relevant differences with `computed(() => ({ a: foo, b: foo2 }))`.
 *
 * @param compMap computed properties mapping: { propName: () => any }
 * @param obj object to be augmented with computed properties
 * @returns
 */
const computedObjOld = <TTarget, TComputed extends { [k: string]: () => any }>(
  compMap: TComputed,
  obj?: TTarget,
) => {
  const comps2Old = Object.entries(compMap).map(
    ([propName, getter]) => [propName, computed(getter)] as const,
  )

  const proxied = new Proxy(obj ?? {}, {
    get(target, prop, receiver) {
      const comp = comps2Old.find(([propName]) => propName === prop)
      if (!!comp) {
        return comp[1].value
      }
      return Reflect.get(target, prop, receiver)
    },
  })

  return proxied as Prettify<
    TTarget & { readonly [K in keyof TComputed]: ReturnType<TComputed[K]> }
  >
}

const computedObj2 = <TTarget, TComputed extends { [k: string]: () => any }>(
  compMap: TComputed,
  obj?: TTarget,
) => {
  const comps2Old = Object.entries(compMap).map(
    ([propName, getter]) => [propName, computed(getter)] as const,
  )

  const proxied = new Proxy(obj ?? {}, {
    get(target, prop, receiver) {
      const comp = comps2Old.find(([propName]) => propName === prop)
      if (!!comp) {
        return comp[1].value
      }
      return Reflect.get(target, prop, receiver)
    },
  })

  return proxied as Prettify<
    TTarget & { readonly [K in keyof TComputed]: ReturnType<TComputed[K]> }
  >
}

/**
 * Returns a new proxied object augmented with computed properties.
 * It accepts
 * - either a map of computeds (or getters that will be transformed into computeds).
 * - or a function that will be called with the property name and should return the computed value.
 * - an optional object to be augmented with the computed properties.
 *
 * Returned object will expose getters that will lazily evaluate computed properties (and cache them).
 *
 * relevant differences with just using: `computed(() => ({ a: foo, b: foo2 }))`
 * - properties are individually computed only when accessed
 * - no `.value` access needed
 * - it's possible to merge them in another object
 *
 * @param mapOrFn computed properties mapping: `{ propName: () => any, [...] }` or `(key: string) => any`
 * @param obj object to be augmented with computed properties
 * @returns
 */
export const computedObj = <
  TTarget extends object,
  TComputed extends
    | Record<string, (() => any) | ComputedRef<any>>
    | ((key: string) => any),
>(
  mapOrFn: TComputed,
  obj?: TTarget,
) => {
  const computedRecord = {} as Record<string, ComputedRef<any>>
  // get the computed value: if no computed is found, build it first.
  const getCached =
    typeof mapOrFn === 'function'
      ? (prop: string) => {
          computedRecord[prop] ??= computed(() => mapOrFn(prop))
          return computedRecord[prop].value
        }
      : (prop: string) => {
          const comp = mapOrFn[prop]
          if (!!comp) {
            computedRecord[prop] ??=
              typeof comp === 'function' ? computed(comp) : comp
            return computedRecord[prop].value
          }
        }

  const proxied = new Proxy(obj ?? {}, {
    get(target, prop, receiver) {
      // first check if prop in obj (if yes return it)
      if (!!obj && prop in obj) {
        return obj[prop as keyof TTarget]
      }

      // no symbol keys
      if (typeof prop === 'string') return getCached(prop)
    },
  })

  type CompRecord = TComputed extends (key: string) => any
    ? Readonly<Record<string, ReturnType<TComputed>>>
    : {
        readonly [K in keyof TComputed]: TComputed[K] extends () => any
          ? ReturnType<TComputed[K]>
          : UnwrapRef<TComputed[K]>
      }

  // type Proxied = undefined extends TTarget ? CompRecord : TTarget & CompRecord
  // type Proxied = Prettify<TTarget & CompRecord>
  // type Proxied = TTarget & CompRecord

  return proxied as TTarget & CompRecord
}
