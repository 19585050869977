import { Issue, TechDebt } from '.'
import { getIssues, getTechDebts } from '..'
import { CFG, LABELS } from '~/services/cfg'

const loadCandidateIssues = () =>
  getIssues(CFG.projects.general.id, {
    milestone: null,
    hasNoneLabels: [LABELS.documentation, LABELS.bugfix],
  }).then((issues) => issues.map((i) => new Issue(i)))

const loadTD = (project: ProjectName) => async () =>
  (await getTechDebts(CFG.projects[project].id)).map((i) => new TechDebt(i))

@store()
class TechDebtsHub {
  @lazy(loadTD('SF')) SF?: TechDebt[]
  @lazy(loadTD('BO')) BO?: TechDebt[]
  @lazy(loadTD('BE')) BE?: TechDebt[]
  get fe() {
    return (this.BO ?? [])
      .concat(this.SF ?? [])
      .sort((a, b) => b.createdAt.diff(a.createdAt))
  }
  get be() {
    return this.BE ?? []
  }
  get all() {
    return this.fe.concat(this.be).sort((a, b) => b.createdAt.diff(a.createdAt))
  }
}

/**
 * readonly hub for issues & tech debts
 */
@store()
class IssuesHub {
  /**
   * unassigned issues (no sprint assigned)
   */
  @lazy(loadCandidateIssues)
  unassignedIssues?: Issue[]

  techDebts = new TechDebtsHub()

  addUnassigned(issue: Issue) {
    this.unassignedIssues?.unshift(issue)
  }

  removeUnassigned(issue: Issue) {
    this.unassignedIssues?.removeByIid(issue.iid)
  }
}

export const issuesHub = new IssuesHub()
