import { IssueBase, Issue } from './index'
import { addSpent, updateWeight } from '..'
import { CFG, LABELS } from '~/services/cfg'
import { issuesHub } from './IssueHub'
import { getProgressStatus } from '../stats'

@store()
export class TechIssue extends IssueBase {
  constructor(
    protected override _fromApi: GLIssue,
    /** general related issue */
    public relatedIssue: Issue,
  ) {
    super(_fromApi)
  }

  get sprint() {
    return this.relatedIssue.sprint
  }

  get techDebtsWeight() {
    return this.techDebts.sum((x) => x.weight ?? 0)
  }

  get techDebts() {
    const projName = this.projectName
    if (projName === 'BE' || projName === 'SF' || projName === 'BO') {
      return (
        issuesHub.techDebts[projName]?.filter(
          (td) => td.relatedTechIssue?.iid === this.iid,
        ) ?? []
      )
    }
    return []
  }
  // #endregion

  get isBudget() {
    return this.labels.includes(LABELS.budget)
  }

  setIsBudget(isBudget: boolean) {
    return this.updateAndPersistLabels(
      [LABELS.budget],
      isBudget ? [LABELS.budget] : [],
    )
  }

  /**
   * indicates if a tech issue belongs to BE or FE project
   */
  get domain() {
    return Object.values(CFG.projects).find((p) => p.id === this.projectId)
      ?.domain
  }

  get startWeight() {
    return this.customData.startWeight
  }

  setStartWeight(startWeight: number) {
    return this.setCustomData('startWeight', startWeight)
  }

  get tShirt() {
    return this.customData.tShirt
  }

  setTShirt(tShirt?: number, debounced = false) {
    return this.setCustomData('tShirt', tShirt, debounced)
  }

  get isEstimated() {
    return this.estimate !== undefined
  }

  get estimate() {
    return this._fromApi.weight ?? undefined
  }

  /**
   * not yet used: to be used instead of current estimate()
   * we need to:
   * - update setEstimate by using APIs (values to be transformed in human readable format, see https://docs.gitlab.com/ee/api/issues.html#set-a-time-estimate-for-an-issue)
   * - check consistency for all the flows using "estimate" (sprint follow-up, result snapshots, planning)
   * - migrate all current sprint and planning weights to the new estimate
   */
  get estimate2() {
    // from seconds to SP
    return this._fromApi.time_stats.time_estimate / (60 * 60 * 4)
  }

  /**
   * best estimate: if estimate is not set, use tShirt size
   */
  get weight() {
    return this.estimate ?? this.tShirt
  }

  async setEstimate(weight?: number, debounced = false) {
    const updated = await this.queue(
      'setEstimate',
      async () => {
        const w = weight === undefined ? null : weight
        return updateWeight(this.projectId, this.iid, w)
      },
      debounced,
    )
    if (updated) this._fromApi.weight = updated.weight
    await this.loadWeightEvents()
  }

  get isFastTrack() {
    return this.labels.includes(LABELS.fastTrack)
  }

  get sprintStartWeight() {
    if (this.startWeight) return this.startWeight
    const { sprint } = this
    if (sprint?.firstDay) {
      return (
        this.weightEvents
          ?.filter((e) => e.createdAt.isBefore(sprint.firstDay, 'day'))
          .last()?.weight ?? 0
      )
    }
    return 0
  }

  get progress() {
    return getProgressStatus(
      this.sprintStartWeight,
      this.sprintSpentSP,
      this.weight ?? 0,
    )
  }

  get dailyUpdates() {
    const { sprint } = this
    if (!sprint) return
    return sprint.sprintDaysTillNow
      ?.map((d, i) => {
        const [dayStart, dayEnd] = sprint.dayBoundaries![i]!
        const getDailyEvents = <T extends { createdAt: DayJs }>(events?: T[]) =>
          events?.filter((e) => e.createdAt.isBetween(dayStart, dayEnd)) ?? []

        const weightEvents = getDailyEvents(this.weightEvents)
        const spentEvents = getDailyEvents(this.spentTimeEvents)
        const assignmentEvents = getDailyEvents(this.assignmentEvents)
        const spent = spentEvents.sum((e) => e.spent)
        const weight =
          this.weightEvents?.filter((e) => !e.createdAt.isAfter(dayEnd)).last()
            ?.weight ?? this.sprintStartWeight
        const assignment = assignmentEvents.last()?.assignee
        return {
          weightEvents,
          spentEvents,
          assignmentEvents,
          weight,
          spent,
          assignment,
        }
      })
      .map((daily, i, arr) => {
        const prev = arr[i - 1]
        const delta = prev ? daily.weight - prev.weight : 0
        return { ...daily, delta }
      })
  }

  get sprintWeightEvents() {
    return this.dailyUpdates?.flatMap((u) => u.weightEvents)
  }

  get sprintSpentEvents() {
    return this.dailyUpdates?.flatMap((u) => u.spentEvents) ?? []
  }

  get sprintSpentSP() {
    return this.dailyUpdates?.sum((u) => u.spent) ?? 0
  }

  get deliveredWeight() {
    if (this.isBudget) {
      return this.sprintSpentSP
    }
    return this.sprintStartWeight - this.techDebtsWeight
  }

  get deltaSpent() {
    return this.sprintSpentSP - this.sprintStartWeight
  }

  get overEstimate() {
    return Math.max(this.deltaSpent, 0)
  }

  get underEstimate() {
    return Math.max(-this.deltaSpent, 0)
  }

  get eta() {
    // const velocities = this.sprint.velocityForecastAggregates
    const { sprint } = this
    if (
      // velocities &&
      this.teamName &&
      this.domain &&
      sprint?.missingSprintDays
    ) {
      // const velocity = 3000
      const velocity = sprint.stats[this.teamName][this.domain].velocity
      // const velocity = velocities[this.teamName][this.domain]
      const weight = this.weight ?? 0
      if (weight > 0) {
        const missingDays = Math.round(weight / velocity)
        return sprint.missingSprintDays[missingDays]
      }
      const lastCompleteEvent = this.sprintWeightEvents
        ?.filter((e) => e.weight === 0 || e.weight === null)
        .last()
      if (lastCompleteEvent) {
        return lastCompleteEvent.createdAt.startOf('day')
      }
      return sprint.firstDay
    }
  }

  async addSpentTime(time: number) {
    await addSpent(this, time)
    await this.loadNotes()
  }
}
