import type { Progress } from '~/components/burndown/types'
import { Issue } from './models'

const extDomains = ['be', 'fe', 'all', 'noDomain'] as const

export const getDomainStats = (issue: Issue) =>
  createObj(extDomains, (domain) => {
    const collection =
      domain === 'all'
        ? computed(() => issue.techIssues)
        : domain === 'noDomain'
          ? computed(() => issue.techIssues?.filter((ti) => !ti.domain))
          : computed(() =>
              issue.techIssues?.filter((ti) => ti.domain === domain),
            )
    const areTISized = computed(
      () => !!collection.value?.every((ti) => ti.weight !== undefined),
    )
    const areTIEstimated = computed(
      () => !!collection.value?.every((ti) => ti.isEstimated),
    )
    const weight = computed(
      () => collection.value?.sum((ti) => ti.weight ?? 0) ?? 0,
    )

    const sprintStartWeight = computed(
      () => collection.value?.sum((ti) => ti.sprintStartWeight) ?? 0,
    )

    const sprintSpentSP = computed(
      () => collection.value?.sum((ti) => ti.sprintSpentSP ?? 0) ?? 0,
    )

    const sprintSpentEvents = computed(
      () => collection.value?.flatMap((ti) => ti.sprintSpentEvents ?? []) ?? [],
    )

    const sprintWeightEvents = computed(() =>
      collection.value?.flatMap((ti) => ti.sprintWeightEvents),
    )

    const dailyUpdates = computed(() =>
      issue.sprint?.sprintDaysTillNow?.map((d, i) => ({
        weightEvents: collection.value?.flatMap(
          (ti) => ti.dailyUpdates?.[i]!.weightEvents,
        ),
        spentEvents: collection.value?.flatMap(
          (ti) => ti.dailyUpdates?.[i]!.spentEvents,
        ),
        assignmentEvents: collection.value?.flatMap(
          (ti) => ti.dailyUpdates?.[i]!.assignmentEvents,
        ),
        weight: collection.value?.sum(
          (ti) => ti.dailyUpdates?.[i]!.weight ?? 0,
        ),
        spent: collection.value?.sum((ti) => ti.dailyUpdates?.[i]!.spent ?? 0),
        assignment: undefined,
        delta: collection.value?.sum((ti) => ti.dailyUpdates?.[i]!.delta ?? 0),
      })),
    )

    const progress = computed(() =>
      getProgressStatus(
        sprintStartWeight.value,
        sprintSpentSP.value,
        weight.value,
      ),
    )

    const eta = computed(
      () =>
        collection.value?.firstBy(
          (i1, i2) =>
            i1.eta === undefined ||
            (i2.eta !== undefined && i1.eta.isAfter(i2.eta)),
        )?.eta,
    )

    return computedObj({
      collection,
      areTISized,
      areTIEstimated,
      weight,
      sprintStartWeight,
      sprintSpentSP,
      sprintSpentEvents,
      sprintWeightEvents,
      dailyUpdates,
      progress,
      eta,
    })
  })

export const getAggregateStats = (issues: Issue[]) => {
  const pastDays = issues.first()?.sprint?.sprintDaysTillNow ?? []
  return createObj(extDomains, (domain) => {
    const sprintStartWeight = computed(() =>
      issues.sum((issue) => issue.stats[domain].sprintStartWeight),
    )
    const spentSp = computed(() =>
      issues.sum((issue) => issue.stats[domain].sprintSpentSP),
    )
    const weight = computed(() =>
      issues.sum((issue) => issue.stats[domain].weight),
    )
    const dailyAggregate = computed(() =>
      pastDays.map((_d, i) => ({
        weight: issues.sum(
          (issue) => issue.stats[domain].dailyUpdates?.[i]!.weight ?? 0,
        ),
        spent: issues.sum(
          (issue) => issue.stats[domain].dailyUpdates?.[i]!.spent ?? 0,
        ),
        delta: issues.sum(
          (issue) => issue.stats[domain].dailyUpdates?.[i]!.delta ?? 0,
        ),
      })),
    )
    const progress = computed(() =>
      getProgressStatus(sprintStartWeight.value, spentSp.value, weight.value),
    )

    return computedObj({
      spentSp,
      sprintStartWeight,
      weight,
      dailyAggregate,
      progress,
    })
  })
}

export const getProgressStatus = (
  startWeight: number,
  spent: number,
  currentWeight: number,
): Progress => {
  const expectedTotal = currentWeight + spent
  const progressDelta = expectedTotal - startWeight
  const progressDeltaPerc =
    startWeight === 0 ? undefined : progressDelta / startWeight
  const progressStatus =
    progressDeltaPerc === undefined
      ? progressDelta > 0
        ? 'danger'
        : 'ok'
      : progressDeltaPerc > 0.3
        ? 'danger'
        : progressDeltaPerc > 0
          ? 'warning'
          : progressDeltaPerc < 0
            ? 'wow'
            : 'ok'

  return {
    status: progressStatus,
    delta: progressDelta,
    deltaPerc: progressDeltaPerc,
  }
}
