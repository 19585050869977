import { sprintsHub } from '~/api/milestones/models/SprintsHub'
import { glGet } from '~/api/common'
import { CFG, diphlo, getDevByGlId } from '~/services/cfg'
import { storeLoggedActivities, getActivityLog } from '~/api/activities'

// just for testing purpose
const fakeUser = (original: GLUser, override?: User): GLUser =>
  override
    ? {
        ...original,
        id: override.glId,
        username: override.glUsername,
        name: override.name,
        avatar_url: override.avatar_url,
      }
    : original

/**
 * loads user profile
 * @returns
 */
export const getProfile = async (): Promise<GLUser> => {
  const { data } = await glGet<GLUser>(null, 'user')

  // return fakeUser(data, diphlo)
  return fakeUser(data)
}

// TODO: define Teamname as a type
// + remove static typing from api/rest/cfg/types.d.ts and dynamically infer them?

const correspondsBuilder = (glUser: GLUser) => (user: User) =>
  user.glUsername === glUser.username

const containsBuilder = (glUser: GLUser) => (users: User[]) =>
  users.find((user) => user.glUsername === glUser.username)

export type UserRole = 'BE' | 'FE' | 'PO' | 'SM' | 'CTO' | 'SWA' | 'COO'

const getUserRoles = (user: GLUser): UserRole[] => {
  const roles: UserRole[] = []
  const contains = containsBuilder(user)
  const corresponds = correspondsBuilder(user)
  if (Object.values(CFG.swas).find((swa) => corresponds(swa))) {
    roles.push('SWA')
  }
  if (corresponds(CFG.cto)) roles.push('CTO')
  if (corresponds(CFG.coo)) roles.push('COO')
  if (contains(CFG.BErs)) roles.push('BE')
  if (contains(CFG.FErs)) roles.push('FE')
  if (contains(CFG.pos)) roles.push('PO')
  if (contains(CFG.sms)) roles.push('SM')
  return roles
}

@store()
class Me {
  constructor(private profile: GLUser) {}

  private get currentSprintTeam() {
    const teams = sprintsHub.currentSprint?.teams
    if (!teams) return undefined
    return entries(teams).find(([_teamName, team]) =>
      team.BErs.concat(team.FErs)
        .map((dev) => dev.glId)
        .concat(team.poId)
        .includes(this.profile.id),
    )
  }

  get currentTeam() {
    return this.currentSprintTeam
      ? { ...this.currentSprintTeam[1], name: this.currentSprintTeam[0] }
      : undefined
  }

  get roles() {
    return getUserRoles(this.profile)
  }

  get domain() {
    return this.roles.includes('BE')
      ? 'be'
      : this.roles.includes('FE')
        ? 'fe'
        : undefined
  }

  get id() {
    return this.profile.id
  }

  get name() {
    return this.profile.name
  }

  get avatarUrl() {
    return this.profile.avatar_url
  }

  get username() {
    return this.profile.username
  }

  get webUrl() {
    return this.profile.web_url
  }

  // #region activities
  @lazy((me) =>
    me.activityLogId ? getActivityLog(me.activityLogId) : Promise.resolve([]),
  )
  activities?: DayActivityLog[]

  get activityLogId() {
    return getDevByGlId(this.id)?.activityLogId
  }

  async loadActivities() {
    if (this.activityLogId) {
      return (this.activities = await getActivityLog(this.activityLogId))
    }
  }

  async saveDayActivities(day: DayJs, activities: GLLoggedActivity[]) {
    if (!this.activities) {
      throw new Error(
        '`saveDayActivities` called too early: activities not loaded yet',
      )
    }
    if (!this.activityLogId) {
      throw new Error(
        '`saveDayActivities` called for a user without activityLogId',
      )
    }
    const newActivities = cloneDeep(this.activities)
    newActivities.set(
      {
        date: day,
        activities,
      },
      (item) => item.date.isSame(day, 'day'),
    )

    await storeLoggedActivities(this.activityLogId, newActivities)
    await this.loadActivities()
  }

  // #endregion

  hasAnyRole(...roles: UserRole[]) {
    return roles.some((r) => this.roles.includes(r))
  }
}

let me: Me | undefined

export const getMe = () => {
  if (!me) {
    throw new Error('me is undefined, remember to build it first!')
  }
  return me
}

/**
 * to be invoked at app startup to load basic user info
 * @returns
 */
export const initMe = async () => {
  if (!me) {
    me = new Me(await getProfile())
  }
  return me
}

export type TMe = ReturnType<typeof getMe>
