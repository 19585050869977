import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { createVuetify } from 'vuetify'
import { vuetifyTheme } from './2.vuetify/theme'

/**
 * This plugin is a wrapper for Vuetify's Tooltip directive to provide default bindings
 * and to make it easier to use in the app.
 * TODO: define typing for this global directive.
 */

type TText = string | number | null | undefined

type TooltipDirectiveBinding =
  | TText
  | {
      text?: TText
      eager?: boolean
      contentProps?: Record<string, any>
      disabled: boolean
      // ...other props can be typed from docs (they are not exported by Vuetify)
      // (camelCase only!)
      // https://vuetifyjs.com/en/api/v-tooltip/#props
    }

const TOOLTIP_PROPS_DEFAULTS = {
  disabled: false,
  eager: false,
  contentProps: { style: 'white-space: pre-wrap' },
}

const getTextToRender = (ttText: TText) => {
  if (ttText !== undefined && ttText !== null && ttText !== '') {
    return typeof ttText === 'number' ? ttText.toString() : ttText
  }
}

export default defineNuxtPlugin((nuxt) => {
  // a directive consists of a set of hooks https://vuejs.org/guide/reusability/custom-directives#directive-hooks
  // we want to give Tooltip hooks some default bindings for the whole app
  const Tooltip = mapValues((hook) => {
    if (hook === undefined || hook === true) return hook
    return (...args: Parameters<typeof hook>) => {
      let binding = args[1].value as TooltipDirectiveBinding

      // if object (not null), enrich with defaults (if text !== undefined, null, empty => disabled)
      if (typeof binding === 'object') {
        const text = getTextToRender(binding?.text)
        binding =
          text === undefined
            ? { disabled: true }
            : {
                ...TOOLTIP_PROPS_DEFAULTS,
                ...binding,
                text,
              }
      } else {
        const text = getTextToRender(binding)
        binding =
          text === undefined
            ? { disabled: true }
            : {
                ...TOOLTIP_PROPS_DEFAULTS,
                text,
              }
      }

      return hook(
        args[0],
        {
          ...args[1],
          value: binding,
        },
        args[2],
        args[3],
      )
    }
  }, directives.Tooltip)

  // nuxt.vueApp.directive('tooltip', boundTooltip as any)

  const vuetify = createVuetify({
    ssr: true,
    components,
    directives: { ...omit(directives, 'Tooltip'), Tooltip },
    theme: vuetifyTheme,
  })
  nuxt.vueApp.use(vuetify)
})
