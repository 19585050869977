import { SpecialIssue, SPRINTS_RESULTS_ISSUE_ID } from './SpecialIssue'

type GLSprintsResults = Record<number, GLSprintResults>

@store()
class SprintResults extends SpecialIssue<GLSprintsResults> {
  constructor() {
    super(SPRINTS_RESULTS_ISSUE_ID)
  }
  async load() {
    const [data] = await lload(() => this.data)
    return data
  }
  save(sprintNumber: number, results: GLSprintResults) {
    return this._save((data) => {
      data[sprintNumber] = results
      return data
    })
  }
  override get data() {
    return super.data
  }
}

export const sprintResults = new SprintResults()
