import {
  loadTechIssues,
  loadSeeds,
  TechIssue,
  IssueBase,
  type WithSprint,
} from './index'
import { Sprint } from '~/api/milestones/models/Sprint'
import { seedsHub } from '~/api/seeds/models/SeedsHub'
import { type Seed } from '~/api/seeds/models/Seed'
import {
  linkIssues,
  deleteLink,
  closeIssue,
  createIssue,
  updateDescription,
  updateLabels,
  updateMilestone,
} from '..'
import { sprintsHub } from '~/api/milestones/models/SprintsHub'
import { CFG, LABELS } from '~/services/cfg'
import { sprintCandidates } from './specialIssues/SprintCandidates'
import dayjs, { Dayjs } from 'dayjs'
import { getUpdatedDescription } from '../mapping'
import { getAggregateStats, getDomainStats } from '../stats'

const SUMMARY_START = '# Summary'
const SUMMARY_END = '---'
const SUMMARY_REGEX = new RegExp(
  `^${SUMMARY_START}\n([\\s\\S]*?)\n\n${SUMMARY_END}`,
)

const AC_DEFINED_REGEX = /# Acceptance Criteria\n\n/g

export const summaryToDescription = (summary: string) =>
  `${SUMMARY_START}\n${summary}\n\n${SUMMARY_END}`

/**
 * A sprint issue is an issue that belongs to a sprint
 * and it is aware of it, so it can compute stats
 * based on inner data and on the sprint data (config, etc.)
 */
@store()
export class Issue extends IssueBase {
  sprint?: Sprint
  @lazy((i) => loadSeeds(i.iid)) private _seedLinks?: GLIssueLink[]

  constructor(fromApi: GLIssue, sprintBelongingTo?: Sprint) {
    super(fromApi)
    this.sprint = sprintBelongingTo
  }

  /**
   * Returns all related OPEN t. issues
   */
  @lazy((i) =>
    loadTechIssues(i.iid).then((tis) =>
      tis
        .filter((ti) => ti.state !== 'closed')
        .map((ti) => new TechIssue(ti, i)),
    ),
  )
  techIssues?: TechIssue[]

  /**
   * sets the issue's and its tech issues' workflow state to 'TODO'
   * @param sprintId
   */
  async setAllAsTodo() {
    const labels = this.getUpdatedWorkflowLabels(LABELS.todo)
    if (!this.techIssues) {
      throw new Error(
        `No tech issues found for issue #${this.iid} ${this.title} (${this.webUrl})`,
      )
    }
    // move all tech issues to todo
    const tiInTodoPromise = Promise.all(
      this.techIssues.map((ti) => ti.updateWorkflowState(LABELS.todo)),
    )
    // move the issue to todo and assign the sprint
    const updated = await updateLabels(
      CFG.projects.general.id,
      this.iid,
      labels,
    )
    this._fromApi.labels = updated.labels
    await tiInTodoPromise
  }

  async assignSprint(): Promise<Issue>
  async assignSprint(sprint: Sprint): Promise<WithSprint<Issue>>
  async assignSprint(sprint?: Sprint) {
    const updated = await updateMilestone(
      CFG.projects.general.id,
      this.iid,
      sprint?.id ?? null,
    )
    this._fromApi.milestone = updated.milestone
    this.sprint = sprint
    return this as Issue
  }

  get summary() {
    if (this._fromApi.description) {
      return this._fromApi.description.match(SUMMARY_REGEX)?.[1] ?? ''
    }
    return ''
  }

  async setSummary(summary: string, debounced = false) {
    const updated = await this.queue(
      'setSummary',
      () => {
        const wrapped = summaryToDescription(summary)
        const match = this._fromApi.description?.match(SUMMARY_REGEX)
        this._fromApi.description = match
          ? this._fromApi.description!.replace(match[0], wrapped)
          : `${wrapped}\n${this._fromApi.description}`

        return updateDescription(
          this.projectId,
          this.iid,
          this._fromApi.description ?? '',
        )
      },
      debounced,
    )
    if (updated) this._fromApi.description = updated.description
  }

  async addTechIssue(
    projectId: number,
    title: string,
    description?: string,
    tShirt?: number,
    weight?: number,
  ) {
    description = getUpdatedDescription({ notes: '', tShirt }, description)
    const issue = await createIssue(projectId, title, { description, weight })
    linkIssues(this.projectId, this.iid, projectId, issue.iid)
    const newTechIssue = new TechIssue(issue, this)
    this.techIssues?.push(newTechIssue)
    return newTechIssue
  }

  async closeTechIssue(techIssue: TechIssue) {
    await closeIssue(techIssue.projectId, techIssue.iid)
    this.techIssues?.removeByIid(techIssue.iid)
  }

  /**
   * returns 'any' if no domain-specific tech issues are required,
   * returns 'fe' if no tech issues from BE are required and 'be' if
   * no tech issues from FE are required,
   * otherwise 'fs' is returned.
   */
  get domain() {
    if (this.techIssues?.every(({ domain }) => !domain)) return 'any'
    if (this.techIssues?.every(({ domain }) => domain !== 'fe')) return 'be'
    if (this.techIssues?.every(({ domain }) => domain !== 'be')) return 'fe'
    return 'fs'
  }

  get isRAndD() {
    return this.labels.includes(LABELS.RAndD)
  }

  toggleIsRAndD() {
    return this.persistLabels(this.labels.toggleI(LABELS.RAndD))
  }

  /**
   * Returns true if an issue development & test is completed (issue considered as releases)
   * and we use this information for release summary and stats.
   * We consider the issue is over if it is in production or ready for production.
   * @param labels
   * @returns
   */
  isIssueOver(labels: string[]) {
    return labels.includesAny([LABELS.readyForProd, LABELS.inProd])
  }

  /**
   * Returns true if an issue development & test is over on a specific date.
   * @param date
   * @returns
   */
  isOverToDate(date?: Dayjs) {
    return this.isIssueOver(this.getLabelsToDate(date ?? dayjs()))
  }

  /**
   * Returns true if an issue development & test is over on the current date.
   */
  get isOver() {
    return this.isIssueOver(this.labels)
  }

  get isReleased() {
    return this.state === 'closed' || this.isOver
  }

  get weight() {
    return this.stats.all.weight
  }

  get hasTechIssues() {
    return this.techIssues && this.techIssues.length > 0
  }

  get budgetTechIssues() {
    return this.techIssues?.filter((ti) => ti.isBudget) ?? []
  }

  get hasBudgetTechIssues() {
    return this.budgetTechIssues.length > 0
  }

  get inaccuracy() {
    // biggest wins (size matters).
    if (this.labels.includes(LABELS.missingTest3)) return 3
    if (this.labels.includes(LABELS.missingTest2)) return 2
    if (this.labels.includes(LABELS.missingTest1)) return 1
    return 0
  }

  get techDebts() {
    return this.techIssues?.flatMap((ti) => ti.techDebts) ?? []
  }

  get isWorkable() {
    return this.hasTechIssues && this.stats.all.areTIEstimated
  }

  /**
   * Returns true if the issue has acceptance criteria defined (validated through description regex)
   */
  get areACDefined() {
    return (
      !!this._fromApi.description &&
      AC_DEFINED_REGEX.test(this._fromApi.description)
    )
  }

  get planningWfState() {
    const state = this.workflowState
    const workableCandidates = [
      LABELS.roadmapReady,
      LABELS.design,
      LABELS.analysis,
    ]
    const isFromRoadmapReady =
      state && (workableCandidates as string[]).includes(state)
    if (isFromRoadmapReady)
      return this.isWorkable ? LABELS.workable : LABELS.roadmapReady
    return state
  }

  /**
   * tech blocks: tech tasks to be done before some of this issue's tech tasks can be started
   */
  get techBlockedBy() {
    if (!this.techIssues || this.techIssues.some((ti) => !ti.blockedByLinks))
      return undefined
    return this.techIssues.flatMap((ti) => ti.blockedByLinks!)
  }

  /**
   * master issues depending on this issue
   */
  get blocks() {
    return (
      sprintsHub.futureIssues?.filter((i) =>
        i.blockedByLinks?.find((i) => i.iid === this.iid),
      ) ?? []
    )
  }

  private get blockedByIids() {
    return this.blockedByLinks?.map((i) => i.iid)
  }

  get blockedByPast() {
    const { blockedByIids } = this
    return !!blockedByIids && !!sprintsHub.pastIssues
      ? sprintsHub.pastIssues.filter((i) => blockedByIids.includes(i.iid))
      : []
  }

  get blockedByCurrent() {
    const { blockedByIids } = this
    return !!blockedByIids && !!sprintsHub.currentSprintIssues
      ? sprintsHub.currentSprintIssues.filter((i) =>
          blockedByIids.includes(i.iid),
        )
      : []
  }

  get blockedByFuture() {
    const { blockedByIids } = this
    return !!blockedByIids && !!sprintsHub.futureIssues
      ? sprintsHub.futureIssues.filter((i) => blockedByIids.includes(i.iid))
      : []
  }

  get blockedBy() {
    return [
      ...this.blockedByPast,
      ...this.blockedByCurrent,
      ...this.blockedByFuture,
    ]
  }

  /**
   * when true for a master, the activity is just a reference without tech issues
   */
  get isContainerOnly() {
    return !!this.customData.containerOnly
  }

  async setContainerOnly(containerOnly: boolean) {
    return this.setCustomData('containerOnly', containerOnly)
  }

  get isMaster() {
    return !!this.customData.isMaster
  }

  get isSlave() {
    return this.blocks.length > 0
  }

  /**
   * @param isMasterOrChildren set as master without dependencies (true or undefined),
   * or with dependencies iids (number[]) or unset as master (false)
   */
  async setMaster(isMasterOrChildren: boolean | number[] = true) {
    const isMaster = !!isMasterOrChildren
    const promises: Promise<unknown>[] = []
    if (this.isMaster !== isMaster) {
      promises.push(this.setCustomData('isMaster', isMaster))
    }
    // compute dependencies to be removed or added
    const newDeps =
      isMasterOrChildren instanceof Array ? isMasterOrChildren : []

    // we just deal with general issues, leaving untouched other relations
    const oldDeps =
      this.blockedByLinks
        ?.filter((i) => i.project_id === CFG.projects.general.id)
        .map((i) => i.iid) ?? []
    const toBeAdded = newDeps.filter((i) => !oldDeps.includes(i))
    const toBeRemoved =
      this.blockedByLinks
        ?.filter(
          (i) =>
            i.project_id === CFG.projects.general.id &&
            !newDeps.includes(i.iid),
        )
        .map((i) => i.issue_link_id) ?? []

    // add dependencies
    promises.push(
      ...toBeAdded.map((iid) =>
        linkIssues(
          CFG.projects.general.id,
          this.iid,
          CFG.projects.general.id,
          iid,
          'is_blocked_by',
        ),
      ),
    )

    // remove dependencies
    promises.push(
      ...toBeRemoved.map((iid) =>
        deleteLink(CFG.projects.general.id, this.iid, iid),
      ),
    )

    await Promise.all(promises)

    // since "create an issue link" does not return issue_link_id that is needed to delete it later, if needed
    // we need to reload all blockedBy
    await this.loadBlockedBy()
  }

  /**
   * shortcut to the assigned sprint last day
   * TODO: it should always be defined, no sprint object without end date
   * should be allowed by design
   *
   */
  get releaseDate() {
    return this.sprint?.lastDay
  }

  get seeds() {
    const links = this._seedLinks
    if (seedsHub.seeds && links) {
      return seedsHub.seeds.filter((s) =>
        links.map((s) => s.iid).includes(s.iid),
      )
    }
  }

  async setSeeds(seeds: Seed[]) {
    const actual = this._seedLinks
    if (actual === undefined) throw new Error('Seeds not loaded yet')
    // compute relatedTo links to be deleted and links to be created
    const actualLinksIids = actual.map((s) => s.iid)
    const newLinksIids = seeds.map((s) => s.iid)
    const toDelete = actual.filter((s) => !newLinksIids.includes(s.iid))
    const toCreate = newLinksIids.filter(
      (iid) => !actualLinksIids.includes(iid),
    )

    const deletePromise = Promise.all(
      toDelete.map((s) =>
        deleteLink(this.projectId, this.iid, s.issue_link_id),
      ),
    )
    const createPromise = Promise.all(
      toCreate.map((iid) =>
        linkIssues(this.projectId, this.iid, this.projectId, iid),
      ),
    )
    await Promise.all([createPromise, deletePromise])

    // NO optimistic update, reload of seeds.
    this._seedLinks = await loadSeeds(this.iid, true)
  }

  get fastTrackTechIssues() {
    return this.techIssues?.filter((ti) => ti.isFastTrack) ?? []
  }

  // shortcut
  get eta() {
    return this.stats.all.eta
  }

  // shortcut
  get sprintSpentSP() {
    return this.stats.all.sprintSpentSP
  }

  // shortcut
  get progress() {
    return this.stats.all.progress
  }

  // shortcut
  get sprintStartWeight() {
    return this.stats.all.sprintStartWeight
  }

  // shortcut
  get sprintSpentEvents() {
    return this.stats.all.sprintSpentEvents
  }

  /**
   * Returns all related CLOSED t. issues
   */
  get closedTechIssues() {
    return this.techIssues?.filter((i) => i.isClosed) as TechIssue[] | undefined
  }

  get dailyUpdates() {
    return this.sprint?.sprintDaysTillNow?.map((d, i) => ({
      weightEvents:
        this.techIssues?.flatMap(
          (ti) => ti.dailyUpdates?.[i]!.weightEvents ?? [],
        ) ?? [],
      spentEvents:
        this.techIssues?.flatMap(
          (ti) => ti.dailyUpdates?.[i]!.spentEvents ?? [],
        ) ?? [],
      assignmentEvents:
        this.techIssues?.flatMap(
          (ti) => ti.dailyUpdates?.[i]!.assignmentEvents ?? [],
        ) ?? [],
      weight:
        this.techIssues?.sum((ti) => ti.dailyUpdates?.[i]!.weight ?? 0) ?? 0,
      spent:
        this.techIssues?.sum((ti) => ti.dailyUpdates?.[i]!.spent ?? 0) ?? 0,
      assignment: undefined,
      delta:
        this.techIssues?.sum((ti) => ti.dailyUpdates?.[i]!.delta ?? 0) ?? 0,
    }))
  }

  readonly stats = getDomainStats(this)

  // /**
  //  * NOTE: after unassigning it, the current issue should be dismissed and
  //  * a new one (Issue) is returned
  //  * @returns the issue with the sprint removed
  //  */
  // unassignSprint() {
  //   return this.assignSprint()
  //   // return issueR(this._fromApi)
  // }

  static computeAggregate(issues: Issue[]) {
    return getAggregateStats(issues)
  }

  /**
   * return candidacy data (sprint, nth flag) if candidated
   */
  get candidate() {
    const candidate = sprintCandidates.issues?.findByIid(this.iid)
    if (candidate) {
      const { iid, nth, sprintNumber } = candidate
      const sprint = sprintsHub.sprintsAndUnplanned?.find(
        (s) => s.number === sprintNumber,
      )
      return {
        iid,
        nth,
        sprint,
      }
    }
  }
  /**
   * candidates the issue to a sprint if sprintNumber is provided, otherwise deletes its candidacy
   * @param sprintNumber
   */
  candidateAssign(sprintNumber?: number) {
    sprintCandidates.setAssignation(this.iid, sprintNumber)
  }
  /**
   * toggle candidacy nth flag
   */
  candidateToggleNth() {
    sprintCandidates.toggleNth(this.iid)
  }
}

export type IssueAggregateStats = NonNullable<
  ReturnType<typeof Issue.computeAggregate>
>
