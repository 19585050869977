export const isDefined = <T>(x: T | undefined): x is T => x !== undefined

/**
 * Returns the typed version of Object.keys
 * @param obj
 * @returns
 */
export const keys = <T extends Object>(obj: T): (keyof T)[] =>
  Object.keys(obj) as (keyof T)[]

/**
 * Creates an object with the same keys as object and values generated by running each own enumerable
 * string keyed property of object through iteratee. The iteratee is invoked with three arguments: (value, key, object).
 * @param {Function} mapFn
 * @param {Object} obj
 */
export const mapValues = <Source extends Object, TargetVal>(
  mapFn: (value: ValueOf<Source>, key: keyof Source, obj: Source) => TargetVal,
  obj: Source,
) =>
  keys(obj).reduce(
    (acc, key) => ({ ...acc, [key]: mapFn(obj[key], key, obj) }),
    {},
  ) as Record<keyof Source, TargetVal>

/**
 * util to filter objects
 * @param keepFn filter function that accept as first param value and second key of object
 * @param obj object we want filter
 * @returns object with keys filtered
 */
export const filterObject = <Source extends {}>(
  keepFn: (val: ValueOf<Source>, key?: keyof Source, obj?: Source) => boolean,
  obj: Source,
) =>
  keys(obj).reduce(
    (acc, key) => ({
      ...acc,
      ...(keepFn(obj[key], key, obj) ? { [key]: obj[key] } : {}),
    }),
    {},
  ) as Partial<Source>

export const omit = <T extends object, K extends keyof T>(
  obj: T,
  ...keys: K[]
): Prettify<Omit<T, K>> => {
  const shallowClone = { ...obj }
  keys.forEach((key) => delete shallowClone[key])
  return shallowClone
}

/**
 * Returns the typed version of Object.entries
 * @param obj
 */
export const entries = <T extends Object>(obj: T): Entries<T> =>
  Object.entries(obj) as any

/**
 * NON-efficient way to compare 2 objects.
 * Use with caution & at your own risk.
 *
 * @param obj1
 * @param obj2
 * @returns true if obj are equals
 */
export const inefficientEquals = (obj1: any, obj2: any) =>
  JSON.stringify(obj1) === JSON.stringify(obj2)

/**
 * Checks if the passed parameter is a Date
 * @param val
 */
const isDate = (val: any) =>
  val && Object.prototype.toString.call(val) === '[object Date]' && !isNaN(val)

/**
 * This function clones in a deep way any kind of data including arrays and objects
 * Check the documentation below for further details
 * https://stackoverflow.com/questions/34480936/how-to-clone-a-javascript-object-including-getters-and-setters
 * @param item unknown
 */
export const cloneDeep = <T>(item: T): T => {
  // null is considered an object in JS, so we must exclude from the ist of checks below,
  // because it would pass and break the function
  if (item === null) return item

  if (typeof item !== 'object') {
    return item
  } else if (isDate(item)) {
    return new Date(item as unknown as Date) as any
  } else if (Array.isArray(item)) {
    return item.map(cloneDeep) as unknown as T
  } else {
    return Object.setPrototypeOf(
      mapValues((v) => cloneDeep(v), item),
      Object.getPrototypeOf(item),
    )
  }
}

// https://levelup.gitconnected.com/how-to-get-a-perfect-deep-equal-in-javascript-b849fe30e54f
export const deepEqual = <T>(objA: T, objB: T, map = new WeakMap()) => {
  // P1
  if (Object.is(objA, objB)) return true

  // P2
  if (objA instanceof Date && objB instanceof Date) {
    return objA.getTime() === objB.getTime()
  }
  if (objA instanceof RegExp && objB instanceof RegExp) {
    return objA.toString() === objB.toString()
  }

  // P3
  if (
    typeof objA !== 'object' ||
    objA === null ||
    typeof objB !== 'object' ||
    objB === null
  ) {
    return false
  }

  // P4
  if (map.get(objA) === objB) return true
  map.set(objA, objB)

  // P5
  const keysA = Reflect.ownKeys(objA)
  const keysB = Reflect.ownKeys(objB)

  if (keysA.length !== keysB.length) {
    return false
  }

  for (let i = 0; i < keysA.length; i++) {
    if (
      !Reflect.has(objB, keysA[i]!) ||
      !deepEqual((objA as any)[keysA[i]!], (objB as any)[keysA[i]!], map)
    ) {
      return false
    }
  }

  return true
}

export const equals = <T>(
  objA: T,
  objB: T,
  ...args: ((item: T) => unknown)[]
) => args.every((fn) => deepEqual(fn(objA), fn(objB)))

export const assign = <T extends {}, D>(
  target: T,
  decorator: D & ThisType<D & T>,
) => Object.assign(target, decorator)

export const createObj = <T extends string, U>(
  keys: readonly T[],
  creator: (key: T) => U,
) =>
  keys.reduce(
    (acc, key) => ({ ...acc, [key]: creator(key) }),
    {} as Record<T, U>,
  )
