import { getAllFiltered, glGetAll } from '~/api/common'
import { CFG, LABELS } from '~/services/cfg'
import { Bug } from './index'

const generalProjectId = CFG.projects.general.id

const fetchBugs = async (state: GLMRState) =>
  (
    await getAllFiltered(
      (params) =>
        glGetAll<GLIssue[]>(generalProjectId.toString(), `issues`, params),
      {
        hasAllLabels: [LABELS.bugfix],
        state,
      },
    )
  ).map((i) => new Bug(i))

@store()
class BugsHub {
  @lazy(fetchBugs.bind(null, 'opened')) openBugs?: Bug[]
  @lazy(fetchBugs.bind(null, 'closed')) closedBugs?: Bug[]

  get bugs() {
    if (this.openBugs && this.closedBugs) {
      return [...this.openBugs, ...this.closedBugs]
    }
  }

  get count() {
    return this.bugs?.length ?? 0
  }

  get hiPrioCount() {
    return this.bugs?.filter((b) => b.priority === 'High') ?? []
  }

  get unclassified() {
    return this.bugs?.filter((b) => !b.priority) ?? []
  }
}

export const bugsHub = new BugsHub()
