import { CFG, allUsers } from '~/services/cfg'
import { getMe } from '~/api/user/models/Me'
import { Discussion } from './Discussion'
import {
  KSCRUM_DISCUSSION_SYMBOL,
  KSCRUM_REOPENED_SYMBOL,
  KSCRUM_SOLVED_SYMBOL,
  discussionRegex,
} from '..'
import dayjs from 'dayjs'
const users = allUsers.map((u) => ({
  user: u,
  glUserRegex: new RegExp(`@${u.glUsername}`, 'g'),
}))

const PREVIEW_LENGTH = 150

@store()
export class DiscussionNote {
  constructor(
    private fromApi: GLNote,
    private parentDiscussion: Discussion,
  ) {}
  get id() {
    return this.fromApi.id
  }

  get createdAt() {
    return dayjs(this.fromApi.created_at)
  }

  get updatedAt() {
    return dayjs(this.fromApi.updated_at)
  }
  get author() {
    return allUsers.find((u) => u.glId === this.fromApi.author.id)!
  }
  get body() {
    return this.fromApi.body.replace(discussionRegex, '')
  }
  get preview() {
    const trimmed = this.body.trim()
    return trimmed.length <= PREVIEW_LENGTH
      ? trimmed
      : `${trimmed.substring(0, PREVIEW_LENGTH)}...`
  }
  get system() {
    return this.fromApi.system
  }
  get type() {
    return this.fromApi.type
  }
  get taggedUsers() {
    return users
      .filter((u) => this.body.match(u.glUserRegex))
      .map((u) => u.user)
  }
  get iAmAuthor() {
    return getMe().id === this.author.glId
  }
  get issueIid() {
    return this.fromApi.noteable_iid
  }
  get isComment() {
    return !this.isSolvedMark && !this.isReopenedMark
  }
  get isSolvedMark() {
    return this.fromApi.body.startsWith(KSCRUM_SOLVED_SYMBOL)
  }
  get isReopenedMark() {
    return this.fromApi.body.startsWith(KSCRUM_REOPENED_SYMBOL)
  }
  get isDiscussionStart() {
    return this.fromApi.body.startsWith(KSCRUM_DISCUSSION_SYMBOL)
  }
  /**
   * computed webUrl (in this case GL API is not exposing it)
   * @returns  URL to the note in the web
   */
  get webUrl() {
    const issueProjectId = this.parentDiscussion.parentIssue.projectId
    const projUrl = Object.values(CFG.projects).find(
      (p) => p.id === issueProjectId,
    )?.url
    if (!projUrl) return `error in getting project with id: ${issueProjectId}`
    return `${projUrl}/-/issues/${this.issueIid}#note_${this.id}`
  }
}
