// TODO: to be better re-organized (do we want to move mapping logic to the API wrappers?)

import { type ProgressStatus } from './types'
import type { Dayjs } from 'dayjs'
import { type ChartData, type ChartDataset } from 'chart.js'
import { type IssueAggregateStats, Issue } from '~/api/issues/models'

const DAY_SWITCH_HOUR = 11
/**
 * Returns the boundaries of the day corresponding to the given index in the sprint dates array.
 *
 *
 * @param sprintDates
 * @param i
 * @returns
 */
export const getDayBoundaries = (
  sprintDates: DayJs[],
  i: number,
): [DayJs, DayJs] => {
  const current = sprintDates.at(i)!
  const next = sprintDates.at(i + 1)
  return next === undefined
    ? // last day selects from 11AM to 11AM of the next day (assuming it is not in next sprint)
      [
        current.add(DAY_SWITCH_HOUR, 'hours'),
        current.add(24 + DAY_SWITCH_HOUR, 'hours'),
      ]
    : i === 0
      ? // first day selects from 00:00 to 11AM of the next SPRINT day
        [current, next.add(DAY_SWITCH_HOUR, 'hours')]
      : // all intermediate cases select from 11AM to 11AM of the next SPRINT day
        [
          current.add(DAY_SWITCH_HOUR, 'hours'),
          next.add(DAY_SWITCH_HOUR, 'hours'),
        ]
}

/**
 * - gray: normal progress
 * - green: faster than expected
 * - yellow: slower than expected
 * - red: very slow, risky!
 * @param status
 * @param spent
 * @returns
 */
export const getProgressClassName = (
  status: ProgressStatus,
  spent: number,
): '' | 'bg-green' | 'bg-gray' | 'bg-yellow' | 'bg-red' =>
  status === 'ok'
    ? spent > 0
      ? 'bg-gray'
      : ''
    : status === 'danger'
      ? 'bg-red'
      : status === 'wow'
        ? 'bg-green'
        : 'bg-yellow'

export const getGraphData = (
  pastDays: Dayjs[],
  sprintDates: Dayjs[],
  enrichedIssues: Issue[],
  aggregate: ValueOf<IssueAggregateStats>,
): ChartData<'line', number[], string> => {
  const capacity = aggregate.sprintStartWeight

  const labels = sprintDates.map((date) => date.format('DD/MM'))
  const idealStep = capacity / (sprintDates.length - 1)

  const ideal: ChartDataset<'line', number[]> = {
    label: 'Ideal',
    borderColor: 'rgb(53, 162, 235)',
    backgroundColor: 'rgba(53, 162, 235, 0.5)',
    data: sprintDates.map(
      (_, i) => Math.round((capacity - i * idealStep) * 10) / 10,
    ),
  }
  const current: ChartDataset<'line', number[]> = {
    label: 'Current',
    borderColor: 'rgb(255, 99, 132)',
    backgroundColor: 'rgba(255, 99, 132, 0.5)',
    data: aggregate.dailyAggregate.map((d) => d.weight),
  }
  const issueCount: ChartDataset<'line', number[]> = {
    label: 'Current (issue #)',
    hidden: true,
    borderColor: 'rgb(255, 159, 64)',
    backgroundColor: 'rgba(255, 159, 64, 0.5)',
    data: pastDays.map((day, i) =>
      enrichedIssues.sum((issue) => (issue.isReleased ? 0 : 1)),
    ),
  }

  const techIssuesByOwner = enrichedIssues
    .flatMap((issue) => issue.techIssues ?? [])
    .groupBy((issue) => issue.assignee?.username ?? 'unassigned')

  // TODO: take into account assignee changes
  const techIssueOwners: ChartDataset<'line', number[]>[] = Object.values(
    techIssuesByOwner,
  ).map((issues) => ({
    label: issues[0]!.assignee?.name ?? 'Unassigned',
    hidden: true,
    borderColor: 'rgb(75, 192, 192)',
    backgroundColor: 'rgba(75, 192, 192, 0.5)',
    data: pastDays.map((_day, i) =>
      issues.sum((issue) => issue.dailyUpdates?.[i]?.weight ?? 0),
    ),
  }))

  const singleIssues: ChartDataset<'line', number[]>[] = enrichedIssues.map(
    (issue) => ({
      label: `#${issue.iid} ${issue.title}`,
      hidden: true,
      borderColor: 'rgb(218, 0, 255)',
      backgroundColor: 'rgba(218, 0, 255, 0.5)',
      data: issue.dailyUpdates?.map((d) => d.weight) ?? [],
    }),
  )

  return {
    labels,
    datasets: [ideal, current, issueCount, ...techIssueOwners, ...singleIssues],
  }
}
