import { seedsOrder } from '~/api/issues/models/specialIssues/SeedsOrder'
import { getSeeds } from '..'
import { Seed } from './Seed'

@store()
class SeedsHub {
  @lazy(() => getSeeds().then((seeds) => seeds.map((s) => new Seed(s))))
  private _seeds?: Seed[]
  @lazy(async () => (await lload(() => seedsOrder.data))[0])
  private _orderedIids?: number[]
  get seeds() {
    if (!!this._seeds && !!this._orderedIids)
      return this._seeds.orderByIid(this._orderedIids)
  }

  get openIssues() {
    return this.seeds?.flatMap((seed) => seed.openIssues).distinctByIid() ?? []
  }

  get openIssuesWeight() {
    return this.openIssues.sum((i) => i.weight)
  }

  get rAndDOpenIssues() {
    return this.openIssues.filter((issue) => issue.isRAndD)
  }

  get rAndDOpenIssuesWeight() {
    return this.rAndDOpenIssues.sum((i) => i.weight)
  }

  async addSeed(seed: Seed) {
    const [seeds] = await lload(() => this._seeds)
    seeds.unshift(seed)
  }

  /**
   * seed removal: CLOSE the issue first to be not visible when refetching!
   * @param seed
   * @returns
   */
  async deleteSeed(seed: Seed) {
    if (!this._seeds) throw new Error('seeds not loaded')
    const index = this._seeds.indexOf(seed)
    if (index === -1) return
    this._seeds.splice(index, 1)
  }
  async moveSeed(moveFrom: Seed, moveTo: Seed) {
    if (!this._orderedIids || !this.seeds)
      throw new Error('ordered iids or seeds not loaded')
    if (moveFrom === moveTo) return
    const order = this.seeds.map((seed) => seed.iid)
    const moveFromIndex = order.indexOf(moveFrom.iid)
    const moveToIndex = order.indexOf(moveTo.iid)
    order.move(moveFromIndex, moveToIndex)

    if (order.equals(this._orderedIids)) return

    // optimistic update
    this._orderedIids = order
    const updated = await seedsOrder.save(order)
    this._orderedIids = updated
  }
}

export const seedsHub = new SeedsHub()
