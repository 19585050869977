import { type ValueWithIssuesRef } from '~/components/burndown/types'
import { Sprint } from '../Sprint'
import type { TechIssue } from '~/api/issues/models'

/**
 * computes a `extractedValue` for any tech issue linked to given general issues
 * selects just issues having a tech issues contributing to the aggregate value (extractedValue !== 0)
 * returns the sum of all extractedValue and the list of issues contributing to the aggregate
 * @param issues
 * @param valueExtractor
 * @returns
 */
const getAggregateWithReferences = (
  issues: TechIssue[],
  valueExtractor: (issue: TechIssue) => number,
): ValueWithIssuesRef => {
  const guilty: TechIssue[] = []
  let tot = 0
  issues.forEach((ti) => {
    const computed = valueExtractor(ti)
    if (computed !== 0) {
      tot += computed
      guilty.push(ti)
    }
  })
  return { value: tot, issues: guilty }
}

const getLeaf = (
  sprint: Sprint,
  domain: Domain | 'all',
  team: TeamName | 'all',
) => {
  const releasedIssues = computed(
    () => sprint.issues?.filter((i) => i.isReleased) ?? [],
  )
  const issues = computed(() => {
    let issues = releasedIssues.value
    if (team !== 'all') {
      issues = issues.filter((i) => i.teamName === team)
    }
    if (domain !== 'all') {
      issues = issues.filter(
        (i) => !!i.techIssues?.some((ti) => ti.domain === domain),
      )
    }
    return issues
  })

  const techIssues = computed(() => {
    if (domain === 'all') return issues.value.flatMap((i) => i.techIssues ?? [])
    return issues.value.flatMap(
      (i) => i.techIssues?.filter((ti) => ti.domain === domain) ?? [],
    )
  })

  const nthTechIssues = computed(() =>
    techIssues.value.filter((ti) => ti.relatedIssue.isNth),
  )

  const mandatoryTechIssues = computed(() =>
    techIssues.value.filter((ti) => !ti.relatedIssue.isNth),
  )

  const nthSpent = computed(() =>
    getAggregateWithReferences(nthTechIssues.value, (ti) => ti.sprintSpentSP),
  )

  const nthReleased = computed(() =>
    getAggregateWithReferences(
      nthTechIssues.value,
      (ti) => ti.sprintStartWeight,
    ),
  )

  const mandatorySpent = computed(() =>
    getAggregateWithReferences(
      mandatoryTechIssues.value,
      (ti) => ti.sprintSpentSP,
    ),
  )

  const mandatoryReleased = computed(() =>
    getAggregateWithReferences(
      mandatoryTechIssues.value,
      (ti) => ti.sprintStartWeight,
    ),
  )

  const totalSpent = computed(
    () => mandatorySpent.value.value + nthSpent.value.value,
  )

  const totalReleased = computed(
    () => mandatoryReleased.value.value + nthReleased.value.value,
  )

  const absoluteDelta = computed(() =>
    getAggregateWithReferences(techIssues.value, (ti) =>
      Math.abs(ti.deltaSpent),
    ),
  )

  const overEstimate = computed(() =>
    getAggregateWithReferences(techIssues.value, (ti) => ti.overEstimate),
  )

  const underEstimate = computed(() =>
    getAggregateWithReferences(techIssues.value, (ti) => ti.underEstimate),
  )

  return computedObj({
    issues,
    nthSpent,
    nthReleased,
    mandatorySpent,
    mandatoryReleased,
    totalSpent,
    totalReleased,
    absoluteDelta,
    overEstimate,
    underEstimate,
  })
}

export type ReleaseStatsLeaf = ReturnType<typeof getLeaf>

/**
 * gets release stats tree for a given sprint
 * @param sprint
 * @returns
 */
export const getReleaseStats = (sprint: Sprint) =>
  createObj(['private', 'sitter', 'all'], (teamName) =>
    createObj(['be', 'fe', 'all'], (domain) =>
      getLeaf(sprint, domain, teamName),
    ),
  )
